// import axios from 'axios'

function sendInfo (form) {
    const date = new Date();
    const time = date.getHours() + ':' + date.getMinutes() + ':' + date.getSeconds();
    const today = new Date().toISOString().slice(0, 10);

    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json"},
        body: JSON.stringify({
            FirstName: form.firstname,
            LastName: form.lastname,
            Email: form.email,
            Phone: form.phone,
            PrivacyPolicyAgreement:"yes",
            Member:"False",
            Branch:"",
            Booking:{
            TimeOfDay: time,
            DesiredDate: today,
            QA:[{Q:"Preferred method of contact",A: form.contact},
            {Q:"What are you looking to do?",A:""},
            {Q:"What is your message",A:""},
            {Q:"RefererBefore",A:""},{Q:"Referer",A:"https://campaigns.comsavings.com/bctf"}, {Q:"PostalCode",A: form.postalcode}]}
        })
    };

    console.log(requestOptions.body);
    
    fetch("https://api.comsavings.com/calculator/lead", requestOptions)
        .then((response) => {
            if(response.ok) {
                return response;
            } else {
                throw new Error('Server response wasn\'t OK');
            }
        })
        .catch((error) => {
            console.error("Error:", error);
          });  


    // axios.post ('https://devapi.comsavings.com/calculator/lead', {
    //     FirstName: form.firstname,
    //     LastName: form.lastname,
    //     Email: form.email,
    //     Phone: form.phone,
    //     PrivacyPolicyAgreement:"yes",
    //     Member:"False",
    //     Branch:"",
    //     Booking:{
    //     "TimeOfDay": time,
    //     "DesiredDate": today,
    //     "QA":[{"Q":"Preferred method of contact","A": form.contact},
    //     {"Q":"What are you looking to do?","A":""},
    //     {"Q":"What is your message","A":""},
    //     {"Q":"RefererBefore","A":""},{"Q":"Referer","A":"http://campaigns.comsavings.com/bctf"}]}
    //     }
    // ).then(response =>{
    //     console.log(response.data.message);
    //     return response.data.message;
    // })
    // .catch((e) => {
    //     this.errors.push(e);
    // });
    
}


export { sendInfo };