<template>
    <div class="smallTile">
        <img class="smallLogo" src="@/assets/img/CSCU_logo.svg" alt="CSCU">
    </div>

    <div class="signup">
        <div class="title titlePersonal">
            Personal banking package
        </div>

        <div class="package">
            <h1><strong>Sign Up</strong></h1>
        </div>

        <form class="row g-3 signupform" @submit.prevent="submit">
            <div class="col-md-4">
                <label for="firstname" class="form-label">First name<sup class="requireIcon">*</sup></label>
                <input type="text" v-model="form.firstname" class="form-control" id="firstname" placeholder="your legal first name" required>
            </div>

            <div class="col-md-4">
                <label for="lastname" class="form-label">Last name<sup class="requireIcon">*</sup></label>
                <input type="text" v-model="form.lastname" class="form-control" id="lastname" placeholder="your legal last name" required>
            </div>

            <div class="col-md-4">
                <label for="email" class="form-label">Email<sup class="requireIcon">*</sup></label>
                <input type="email" v-model="form.email" class="form-control" id="email"  placeholder="example@email.com" required>
            </div>

            <div class="col-md-4">
                <label for="phone" class="form-label">Phone<sup class="requireIcon">*</sup></label>
                <input type="tel" v-model="form.phone" class="form-control" id="phone" name="phone" placeholder="123 456 7890" @input="acceptNumber" required>
            </div>

            <div class="col-md-4">
                <label for="postalcode" class="form-label">Postal Code<sup class="requireIcon">*</sup></label>
                <input type="text" v-model="form.postalcode"  class="form-control" id="postalcode" placeholder="VXY 123"  required>
            </div>

            <div class="col-md-12">
                <label for="method" class="form-label">Preferred method of contact<sup class="requireIcon">*</sup></label>
                <br />
                <input type="radio" id="contact1" value="Email" v-model="form.contact" />
                <label for="contact1" class="labelRadio form-label">Email</label>

                <input type="radio" id="contact2" value="Phone" v-model="form.contact" class="secondRadio" />
                <label for="contact2"  class="labelRadio form-label">Phone</label>
            </div>

            <div class="col-12">
                <div class="form-check">
                <input class="form-check-input" type="checkbox" value="" id="checked" v-model="form.checked" required>
                <label class="form-check-label" >
                    I agree to the <a href="https://www.comsavings.com/privacy">Privacy Policy</a> and give <a href="https://www.comsavings.com/legal/marketing-consent">Marketing Consent.</a><sup class="requireIcon">*</sup>
                </label>
                </div>
            </div>

            <p v-if="errors.length">
                <b>Please correct the following error(s):</b>
                <ul>
                <li v-for="(error, index) in errors" :key=index>{{ error }}</li>
                </ul>
            </p>

            <div class="col-12 submitButton">
                <button class="defaultButton" type="submit">Submit</button>
            </div>
        </form>

        <!-- <div class="socialMedia">
            <hr />
            <div class="row">
                <div class="col-md-8 col-4">
                    <img class="socialPurposeLogo" src="@/assets/img/social_purpose_logo.svg" alt="socialLogo">
                </div>

                <div class="col-md-1 col-2">
                    <a href="https://www.instagram.com/comsavings/?hl=en" ><img class="socialMediaLogo" src="@/assets/img/instagram.svg" alt="instagramIcon"></a>
                </div>
                
                <div class="col-md-1 col-2">
                    <a href="https://twitter.com/comsavings?lang=en" ><img class="socialMediaLogo" src="@/assets/img/twitter.svg" alt="twitterIcon"></a>
                </div>

                <div class="col-md-1 col-2">
                    <a href="https://www.facebook.com/CommunitySavings/" ><img class="socialMediaLogo" src="@/assets/img/facebook.svg" alt="facebookIcon"></a>
                </div>

                <div class="col-md-1 col-2">
                    <a href="https://www.linkedin.com/company/community-savings-credit-union-bc/mycompany/" ><img class="socialMediaLogo" src="@/assets/img/linkedin.svg" alt="linkedinIcon"></a>
                </div>
            </div>
        </div> -->
        <footer class="container-fluid">
            <hr />
            <div class="row">
            <div class="col-4 col-sm-3 col-lg-2">
                <img
                class="socialPurposeLogo"
                src="@/assets/img/social_purpose_logo.png"
                alt="socialLogo"
                />
            </div>
            <div class="col-4 col-xl-3 social-media-wrapper">
                <a href="https://www.instagram.com/comsavings/?hl=en"
                ><img
                    class="socialMediaLogo"
                    src="@/assets/img/instagram.svg"
                    alt="instagramIcon"
                /></a>
                <a href="https://twitter.com/comsavings?lang=en"
                ><img
                    class="socialMediaLogo"
                    src="@/assets/img/twitter.svg"
                    alt="twitterIcon" />
                <a href="https://www.facebook.com/CommunitySavings/"
                    ><img
                    class="socialMediaLogo"
                    src="@/assets/img/facebook.svg"
                    alt="facebookIcon" /></a></a
                ><a
                href="https://www.linkedin.com/company/community-savings-credit-union-bc/mycompany/"
                ><img
                    class="socialMediaLogo"
                    src="@/assets/img/linkedin.svg"
                    alt="linkedinIcon"
                /></a>
            </div>
            </div>
        </footer>
  </div>
</template>

<script>
import router from '../router/index.js'
import {sendInfo} from './api/SendForm.js'

export default {

    data() {
      return {
        errors: [],
        form : {
            email: '',
            firstname: '',
            lastname: '',
            phone: '',
            postalcode: '',
            contact: 'Email',
            checked: false
        }
      }
    },

    methods: {
        async submit() {
            if (this.checkForm())
            {
                const response = await sendInfo (this.form);
                
                if(response === undefined) {
                    router.push({name: 'thankyou'}); 
                }
                
                this.errors = [];

                this.errors.push('Our server is temporary offline, please try again');
            }
            
        },
        acceptNumber() {
            let x = this.form.phone.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
            this.form.phone = !x[2] ? x[1] :   x[1] + '-' + x[2] + (x[3] ? '-' + x[3] : '');
        },
        validEmail: function (email) {
            var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(email);
        },
        validPostalCode: function (postalCode) {
            var re = /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$/i;
            return re.test(postalCode);
        },
        validPhone: function (phone) {
            var re = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/;
            return re.test(phone);
        },
        checkForm() {
            this.errors = [];

            if (!this.validEmail(this.form.email)) {
                this.errors.push('Valid email required.');
            }

            if (this.form.email === '') {
                this.errors.push('email is required.');
            }

            if (!this.validPostalCode(this.form.postalcode)) {
                this.errors.push('valid Postal Code required.');
            } 

            if (!this.validPhone(this.form.phone)) {
                this.errors.push('valid phone required.');
            } 

            if (!this.errors.length) {
                return true;
            }

            return false;
        }

    }
}
</script>

<style>

/* .smallTile{
    padding-top: 5%;
    padding-bottom: 5%;
} */
.title {
    padding-bottom: 5% !important;
    text-align: center;
}

.titlePersonal {
    font-weight: 10 !important;
}

.package{
    text-align: left;
    color: #0061A9;
    padding-bottom: 5%;
}

.signup {
    text-align: left;
    margin-left: 5%;
    margin-right: 5%;
}

.requireIcon {
    color: red;
}

.submitButton{
    text-align: center;
}

.labelRadio{
    margin-left: 5px;
}

.secondRadio{
    margin-left: 20px;
}

@media only screen and (max-width: 600px) {
    .submitButton{
        margin-bottom: 20px;
    }
}
</style>