<template>
  <div class="container">
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: 'start',
  components: {
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css?family=Inter');

#app {
  font-family: Inter, sans-serif;
  font-size: 17px;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}


</style>
