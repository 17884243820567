<template>
    <div class="smallTile">
        <img class="smallLogo" src="@/assets/img/CSCU_logo.svg" alt="CSCU">
    </div>

    <div>
        <h1 class="title titleThank">
            <strong>Thank you!</strong>
        </h1>

        <div class="comments">
            A Community Savings team <br />
            member will be contacting you <br /> 
            shortly to finalize your personal <br /> 
            banking package.
        </div>

        <div class="share">
            <div class="shareTitle">
                <strong>Share with your BCTF friends</strong>
            </div>

            <br />

            <div class="sharePart">
                <input v-on:focus="$event.target.select()" 
                ref="generator" id="generator" 
                class="input is-medium is-rounded" type="text" readonly value="campaigns.comsavings.com/bctf">
                
                <button class="defaultButton copyButton" id="copyToClipboard" v-on:click.prevent="copyToClipboard">
                    <div v-show="!display"> Copy <img class="shareIcon" src="@/assets/img/share_icon.svg" alt="shareIcon" ></div>
                    <div v-show="display"> Copied <img class="shareIcon" src="@/assets/img/share_icon.svg" alt="shareIcon" ></div>
                </button>
            </div>

        </div>

        <div>
            <div class="recommand">
                <strong>Find the right fit for your financial needs</strong>
            </div>

            <div class="row">
                <div class="col-4">
                    <div class="row  recommandIcons">
                        <div class="col-12">
                            <a href="https://www.comsavings.com/you/discover-mortgages" ><img class="smallIcon" src="@/assets/img/house_icon.svg" alt="houseIcon"></a>
                        </div>
                        <div class="col-12">
                            <a class="linkIcon" href="https://www.comsavings.com/you/discover-mortgages" >Mortgages</a>
                        </div>
                    </div>
                </div>
                <div class="col-4">
                    <div class="row  recommandIcons">
                        <div class="col-12">
                            <a href="https://www.comsavings.com/you/invest-save" ><img class="smallIcon" src="@/assets/img/investment_icon.svg" alt="investmentIcon"></a>
                        </div>
                        <div class="col-12">
                            <a class="linkIcon" href="https://www.comsavings.com/you/invest-save" >Investments</a>
                        </div>
                    </div>
                </div>
                <div class="col-4">
                    <div class="row  recommandIcons">
                        <div class="col-12">
                            <a href="https://www.comsavings.com/you/get-a-loan" ><img class="smallIcon" src="@/assets/img/loans_icon.svg" alt="loansIcon"></a>
                        </div>
                        <div class="col-12">
                            <a class="linkIcon" href="https://www.comsavings.com/you/get-a-loan" >Loans</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <!-- <div class="socialMediaShow">
            <hr />
            <div class="row">
                <div class="col-md-8 col-4">
                    <img class="socialPurposeLogo" src="@/assets/img/social_purpose_logo.svg" alt="socialLogo">
                </div>

                <div class="col-md-1 col-2">
                    <a href="https://www.instagram.com/comsavings/?hl=en" ><img class="socialMediaLogo" src="@/assets/img/instagram.svg" alt="instagramIcon"></a>
                </div>
                
                <div class="col-md-1 col-2">
                    <a href="https://twitter.com/comsavings?lang=en" ><img class="socialMediaLogo" src="@/assets/img/twitter.svg" alt="twitterIcon"></a>
                </div>

                <div class="col-md-1 col-2">
                    <a href="https://www.facebook.com/CommunitySavings/" ><img class="socialMediaLogo" src="@/assets/img/facebook.svg" alt="facebookIcon"></a>
                </div>

                <div class="col-md-1 col-2">
                    <a href="https://www.linkedin.com/company/community-savings-credit-union-bc/mycompany/" ><img class="socialMediaLogo" src="@/assets/img/linkedin.svg" alt="linkedinIcon"></a>
                </div>
            </div>
        </div> -->

        <footer class="container-fluid FooterThank">
            <hr />
            <div class="row">
            <div class="col-4 col-sm-3 col-lg-2">
                <img
                class="socialPurposeLogo"
                src="@/assets/img/social_purpose_logo.png"
                alt="socialLogo"
                />
            </div>
            <div class="col-4 col-xl-3 social-media-wrapper">
                <a href="https://www.instagram.com/comsavings/?hl=en"
                ><img
                    class="socialMediaLogo"
                    src="@/assets/img/instagram.svg"
                    alt="instagramIcon"
                /></a>
                <a href="https://twitter.com/comsavings?lang=en"
                ><img
                    class="socialMediaLogo"
                    src="@/assets/img/twitter.svg"
                    alt="twitterIcon" />
                <a href="https://www.facebook.com/CommunitySavings/"
                    ><img
                    class="socialMediaLogo"
                    src="@/assets/img/facebook.svg"
                    alt="facebookIcon" /></a></a
                ><a
                href="https://www.linkedin.com/company/community-savings-credit-union-bc/mycompany/"
                ><img
                    class="socialMediaLogo"
                    src="@/assets/img/linkedin.svg"
                    alt="linkedinIcon"
                /></a>
            </div>
            </div>
        </footer>

    </div>
</template>

<script>
export default {

    data() {
      return {
        display: false
      }
    },

    methods: {
        showTool() {
            this.display = true
            setTimeout(() => this.display = false, 800)
        },
        copyToClipboard() {
            navigator.clipboard.writeText('https://campaigns.comsavings.com/bctf'); 
            this.showTool();
        },
    }
}
</script>

<style>
    .titleThank {
        font-weight: 1000 !important;
    }

    .comments{
        padding-bottom: 5%;
    }

    .share{
        background-color: rgba(0, 97, 169, 0.15);
        padding: 2%;
        margin-bottom: 5%;
    }

    .is-rounded {
        border-radius: 10px 0 0 10px;
        height: 30px;
        border: none;
        width: 30%;
    }

    .shareTitle {
        color: #0061A9;
    }

    .recommand{
        padding-bottom: 2%;
    }

    .recommand strong {
        color: inherit !important;
    }

    .shareIcon {
        width: auto;
    }

    .copyButton {
        height: 30px !important;
        width: 10% !important;
        border-radius: 0 10px 10px 0 !important;
    }

    .smallIcon {
        /* width:  90px;
        height: 90px;
        object-fit: cover; */
        width: 10%;
    }

    .linkIcon {
        text-decoration: none;
        color: inherit;
    }

    .socialMediaShow {
        display: inline;
        position: fixed;
        left: 0;
        bottom: 0;
        width: 100%;
        height: auto;
        text-align: left;
    }

    .socialPurposeLogo {
        width: 10%;
    }

    .socialMediaLogo {
        width: 20%;
        margin-top: 10%;
    }

    .col-1 {
        padding-top: 1%;
    }

    

    @media only screen and (max-width: 600px) {
        .comments{
            padding-top: 10%;
        }

        .share{
            margin-bottom: 10%;
        }

        .copyButton {
            width: 100px !important;
        }

        .recommand{
            padding-bottom: 10%;
        }

        .smallIcon {
            width:  70px;
            height: 70px;
            /* object-fit: cover; 
            width: 50%; */
        }

        .socialPurposeLogo {
            width: 50%;
        }

        .socialMediaLogo {
            width: 50%;
        }

        .FooterThank {
            display: block;
            padding-top: 20%;
        }
        
    }

</style>