<template>
  <div class="ThankPage">
    <ThankPage/>
  </div>
</template>


<script>
// @ is an alias to /src
import ThankPage from '@/components/ThankPage.vue'

export default {
  name: 'ThankView',
  components: {
    ThankPage
  }
}
</script>