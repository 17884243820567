<template>
  <div class="bigTile">
    <img class="bigLogo" src="@/assets/img/CSCU_logo.svg" alt="CSCU" />
  </div>

  <div class="background">
    <div class="bctf">
      <img src="@/assets/img/BCTF_logo.svg" alt="BCTF" />
    </div>

    <div class="des">
      <div class="title">Start banking with us</div>

      <div class="account-description-wrapper">
        <div>
          An exclusive personal banking <br />
          package for BCTF members.
        </div>
        <ul class="accountDescription">
          <li>Free Premium chequing account<sup>1</sup></li>
          <li>2 months interest-free line of credit<sup>2</sup></li>
          <li>$100 cash bonus <sup>3</sup></li>
        </ul>
        <strong class="accountDescriptionStr">and more</strong>
      </div>
    </div>

    <div class="startButton">
      <button
        class="defaultButton"
        @click="redirectSign"
        role="link"
        data-href="signup"
      >
        Get Started!
      </button>
    </div>

    <div class="terms">
      <a href="https://www.comsavings.com/legal/bctf_termsconditions"
        >Terms and Conditions</a
      >
    </div>
  </div>

  <footer class="container-fluid">
    <div class="row">
      <div class="col-4 col-sm-3 col-lg-2">
        <img
          class="socialPurposeLogo"
          src="@/assets/img/social_purpose_logo.png"
          alt="socialLogo"
        />
      </div>
      <div class="col-4 col-xl-3 social-media-wrapper">
        <a href="https://www.instagram.com/comsavings/?hl=en"
          ><img
            class="socialMediaLogo"
            src="@/assets/img/instagram.svg"
            alt="instagramIcon"
        /></a>
        <a href="https://twitter.com/comsavings?lang=en"
          ><img
            class="socialMediaLogo"
            src="@/assets/img/twitter.svg"
            alt="twitterIcon" />
          <a href="https://www.facebook.com/CommunitySavings/"
            ><img
              class="socialMediaLogo"
              src="@/assets/img/facebook.svg"
              alt="facebookIcon" /></a></a
        ><a
          href="https://www.linkedin.com/company/community-savings-credit-union-bc/mycompany/"
          ><img
            class="socialMediaLogo"
            src="@/assets/img/linkedin.svg"
            alt="linkedinIcon"
        /></a>
      </div>
    </div>
  </footer>
</template>

<script>
import router from "../router/index.js";

export default {
  name: "Start",

  methods: {
    redirectSign() {
      router.push({ name: "signup" });
    },
  },
};
</script>

<style>
.bigTile {
  padding-top: 5%;
  padding-bottom: 5%;
}

.bctf {
  padding-left: 5%;
  text-align: left;
}

.background {
  background-color: rgba(0, 97, 169, 0.15);
  /* min-height: 100vh; */
  padding-top: 5%;
  border-radius: 20px 20px 20px 20px;
}

.accountDescription {
  text-align: inline;
  list-style-position: inside;
  padding-top: 5%;
}

.account-description-wrapper {
  width: 77.5%;
  max-width: 450px;
  min-width: 325px;
  display: block;
  margin: auto;
}

.account-description-wrapper strong,
.account-description-wrapper div {
  padding-left: 3.5rem;
}

.account-description-wrapper strong {
  display: block;
}

.account-description-wrapper strong,
.account-description-wrapper li,
.account-description-wrapper div {
  text-align: left;
}

.accountDescription li {
  font-weight: bold;
  color: #0061a9;
}

strong {
  color: #0061a9;
}

.startButton {
  padding-top: 3%;
  padding-bottom: 5%;
}

.terms {
  padding-bottom: 1rem;
}

@media only screen and (max-width: 600px) {
  .bigTile {
    padding-top: 20%;
    padding-bottom: 40%;
    height: 30vh;
  }

  .bctf {
    padding-left: 10%;
    text-align: left;
  }

  .background {
    min-height: 70vh;
    padding-top: 10%;
    border-radius: 20px 20px 0px 0px;
  }

  .accountDescription {
    text-align: inline;
    padding-top: 15%;
    list-style-position: outside;
  }

  .accountDescriptionStr {
    padding-left: 2rem !important;
  }

  .des {
    padding-top: 10%;
  }

  .startButton {
    padding-top: 20%;
  }
}
</style>
