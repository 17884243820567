<template>
  <div class="sign">
    <SignPage/>
  </div>
</template>


<script>
// @ is an alias to /src
import SignPage from '@/components/SignPage.vue'

export default {
  name: 'SignView',
  components: {
    SignPage
  }
}
</script>