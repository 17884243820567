import { createApp,h } from 'vue'
import { createGtm } from '@gtm-support/vue-gtm';
import App from './App.vue'
import router from './router'
import './assets/css/main.css';
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"

const app  = createApp({
    render: ()=>h(App)
});

app.use(router)
app.use(createGtm({
    id: 'GTM-5F8V6LC',
    defer: false,
    nonce: crypto.randomUUID().toString('base64'),
    compatibility: false,
    vueRouter: router,
    trackOnNextTick: false,
}))
app.mount('#app')
